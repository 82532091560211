/* eslint-disable import/no-extraneous-dependencies */
import { ApolloClient, InMemoryCache, from } from '@apollo/client';
import { RetryLink } from 'apollo-link-retry';
import { createUploadLink } from 'apollo-upload-client';
import fetch from 'isomorphic-unfetch';
import { graphqlEndpoint } from '@root/swift.config.js';
import { IntrospectionFragmentMatcher } from 'apollo-cache-inmemory';
import { onError } from 'apollo-link-error';
import {
    setAuth, setLogin, getLoginInfoDashboard, setLoginDashboard,
} from '@helpers/auth';
import { getAppEnv } from '@helpers/env';
import Cookies from 'js-cookie';
import { checkoutKeyCookies } from '@config';

const fragmentMatcher = new IntrospectionFragmentMatcher({
    introspectionQueryResultData: {
        __schema: {
            types: [],
        },
    },
});

const appEnv = getAppEnv();

// handle if token expired
const logoutLink = onError((err) => {
    const { graphQLErrors, networkError } = err;
    if (networkError && typeof window !== 'undefined' && graphQLErrors && graphQLErrors.length > 0 && graphQLErrors[0].status > 500) {
        window.location.href = '/';
    } else if (graphQLErrors && graphQLErrors.length > 0 && typeof window !== 'undefined') {
        const config = Cookies.getJSON(checkoutKeyCookies.config);

        // handling & condition error token ecp or wakanda
        let isExpired = false;

        for (let inc = 0; inc < graphQLErrors.length; inc += 1) {
            if (graphQLErrors[inc].extensions && graphQLErrors[inc].extensions.reason) {
                if (
                    graphQLErrors[inc].extensions.reason.includes('graphql-authorization')
                    || graphQLErrors[inc].extensions.reason.includes("The cart isn't active")
                    || graphQLErrors[inc].extensions.reason.includes('Authorization Cannot Be Null')
                    || graphQLErrors[inc].extensions.reason.includes('Token Unauthorized')
                ) {
                    isExpired = true;
                }
            }
        }

        if (window && window.showSessionExpired && isExpired) {
            window.showSessionExpired();
        } else if (window?.errorPopup) {
            window.errorPopup({
                open: true,
            });
        }
        setTimeout(() => {
            if (isExpired && getLoginInfoDashboard() === 1) {
                setLoginDashboard(0);
                Cookies.remove(checkoutKeyCookies.tokenDashboard);
                window.location.replace('/dashboard/login');
            }
            if (isExpired) {
                let url = '';
                if (config && config.hostname) {
                    if (config.hostname.includes('graphql')) {
                        url = config.hostname.replace('graphql', '');
                    } else {
                        url = config.hostname;
                    }
                }
                if (config && config.ecpType && config.ecpType === 'magento' && url !== '') {
                    url += 'checkout/cart';
                }

                if (config && config.ecpErrorCallbackUrl) {
                    url = config.ecpErrorCallbackUrl;
                }
                setLogin(0);
                setAuth(0);
                window.location.replace(url);
            }
        }, 3000);
    }
});

export default function createApolloClient(initialState, ctx) {
    let hostname = '';
    if (ctx && ctx.req && ctx.req.hostname) {
        hostname = ctx.req.hostname;
    }
    if (typeof window !== 'undefined') {
        hostname = window.location.hostname;
    }

    if (hostname && hostname !== '' && (!hostname.includes('http') || !hostname.includes('https'))) {
        hostname = appEnv === 'local' ? `http://${hostname}` : `https://${hostname}`;
    }

    const uri = graphqlEndpoint[appEnv] ? graphqlEndpoint[appEnv] : graphqlEndpoint.dev;
    const uriInternal = appEnv === 'local' ? 'http://localhost:3000/internal-graphql' : `${hostname}/internal-graphql`;

    const link = new RetryLink().split(
        (operation) => operation.getContext().request === 'internal',
        createUploadLink({
            uri: uriInternal, // Server URL (must be absolute)
            credentials: 'same-origin', // Additional fetch() options like `credentials` or `headers`
            fetch,
        }),
        createUploadLink({
            uri, // Server URL (must be absolute)
            credentials: 'same-origin', // Additional fetch() options like `credentials` or `headers`
            fetch,
            useGETForQueries: true,
        }),
    );
    return new ApolloClient({
        ssrMode: Boolean(ctx),
        link: from([logoutLink, link]),
        cache: new InMemoryCache({
            fragmentMatcher,
            typePolicies: {
                Query: {
                    fields: {
                        getHistoryOrder: {
                            read(existing) {
                                return existing && { ...existing };
                            },
                            merge(existing, incoming) {
                                const merged = existing ? { ...existing, order: existing.order.slice(0) } : { order: [], pageInfo: {} };
                                if (incoming.order?.length > 0) {
                                    if (merged.order) {
                                        merged.order = [...merged.order, ...incoming.order];
                                    }
                                    merged.pageInfo = { ...incoming.pageInfo };
                                }
                                return merged;
                            },
                        },
                    },
                },
            },
        }).restore(initialState),
        // reference https://www.apollographql.com/docs/react/development-testing/developer-tooling/#apollo-client-devtools
        // eslint-disable-next-line no-underscore-dangle
        connectToDevTools: typeof window !== 'undefined' && window.__APOLLO_CLIENT__ && appEnv === 'local',
        resolvers: {},
    });
}
